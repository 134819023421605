<template>
  <div class="bg-site-light pt-4 mb-4">
    <div class="container">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
        
        <slot>
          <div class="flex-sm-fill">
            <h2 class="mb-0">
              <span class="text-secondary">
                {{ header }}
              </span>
            </h2>
            <h5 class="mr-auto">
              <slot name="subHeader">
                {{ subHeader }}
              </slot>
            </h5>
          </div>
        </slot>

      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'BasePageHeader',
  props: ['header', 'subHeader'],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>