import Ticket from "../../../apis/Ticket";

/**
 * Fetch Tickets to store
 * 
 * @param TicketSettings (none so far)
 */
 export const fetchActiveTickets = ({ commit }) => {
  Ticket.all().then(response => {
    commit('SET_TICKETS', {items: response.data.items, total: response.data.total});
  }).catch((err) => {
    console.warn(err);
    commit( 'SET_TICKETS', {items: [], total: 0} );
  });
}

/**
 * Get all Tickets
 * 
 * @param {*} query : url param query (no ?)
 */
 export const getTicketsPromise = (store, query) => {
  return Ticket.query(query);
}

/**
 * Get a Ticket
 * 
 * @param {*} id : Ticket ID
 */
export const getTicketPromise = (store, id) => {
  return Ticket.fetch(id);
}

/**
 * Add a Ticket
 * 
 * @param TicketSettings (none so far)
 */
 export const createTicket = (store, ticketSettings) => {
  Ticket.create(ticketSettings).then(response => {
    console.log(response);
    //this.$router.push( { name: 'TicketDetails', params: { id: response.data.id }});
  }).catch((err) => {
    console.log(err);
  });
}

/**
 * Close a Ticket
 * 
 * @param id (none so far)
 */
 export const closeTicket = (store, id) => {
  Ticket.close(id).then(response => {
    console.log(response);
    //this.$router.push( { name: 'TicketDetails', params: { id: response.data.id }});
  }).catch((err) => {
    console.log(err);
  });
}

/**
 * Promise to close a Ticket
 * 
 * @param id (none so far)
 */
 export const closeTicketPromise = (store, id) => {
  return Ticket.close(id);
}

/**
 * Update basic data on Ticket
 * 
 * @param id (none so far)
 * @param formData mixed fields...
 */
 export const updateTicketPromise = (store, {id, data}) => {
  return Ticket.update(id, data);
}


// ! MESSAGES !

/**
 * Get all Messages on a Ticket
 * 
 * @param {*} id : url param query (no ?)
 */
 export const getTicketMessagesPromise = (store, id) => {
  return Ticket.fetchMessages(id);
}

/**
 * Add a Message to a Ticket
 * 
 * @param {*} id : ticket id
 * @param {*} body : content
 */
 export const addTicketMessagePromise = (store, {id, data}) => {
  return Ticket.createMessage(id, data);
}