// Get tickets
export const tickets = state => {
  return state.tickets;
}

// Get channel messages
export const channelMessages = (state) => (channel) => {
  return state.tickets.filter(x => x.channel === channel);
}

// TicketTypes (UNTIL WE GET BACKEND FOR THIS)
export const ticketTypes = (state) => {
  return state.types;
}
