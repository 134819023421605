import Vue from 'vue'
import Vuex from 'vuex'

// State & Basics
import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

// Modules
import user from "./modules/user";
import integration from "./modules/integration";
import order from "./modules/order"; // Current order page (for list in order view)
import job from "./modules/job";
import claim from "./modules/claim";
import product from "./modules/product";
import ticket from "./modules/ticket"; // Current order page (for list in order view)

Vue.use(Vuex);

//
// HELPERS
//

// Not really working yet - But how do we write prototypes in Vue?!
// Cleanup later!!!!
Array.prototype.diff = function(a) {
  let aReturn = [];

  for (let prop in this) {
    if (Object.prototype.hasOwnProperty.call(this, prop)) {
      if (prop in a) {
        if (Array.isArray(a[prop])) {
          let aRecursiveDiff = this[prop].diff(a[prop]);
          if (aRecursiveDiff.length) { aReturn[prop] = aRecursiveDiff; }
        } else {
          if (this[prop] != a[prop]) {
            aReturn[prop] = this[prop];
          }
        }
      } else {
        aReturn[prop] = this[prop];
      }
    }
  }
  return aReturn;
}

//
// VUE
//
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,

  modules: {
    user,
    job,
    integration,
    claim,
    product,
    order,
    ticket,
  }
})
