import Product from "../../../apis/Product";

/**
 * Get all Products
 * 
 * @param {*} filter : Object of key/values
 * @returns Promise of Products
 */
 export const queryProductsPromise = (store, filter) => {

  let queryParts = [];
  for (var p in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, p)) {
      queryParts.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
    }
  }
  const params = queryParts.join('&');

  return Product.all(params);
}


/**
 * Fetch a Product
 * 
 * @param {*} id : Product ID
 * @returns Product
 */
export const getProductPromise = (store, id) => {
  return Product.fetch(id);
}


/**
 * Update a Product
 * 
 * @param {*} id : Product ID
 * @returns Product
 */
export const updateProductPromise = (store, id) => {
  return Product.update(id);
}
