import Api from "./Api";

const END_POINT = '/user';

export default {
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}/full`);
  },

  fetchPrintFiles(id) {
    return Api.get(`${END_POINT}/print-files?id=${id}`);
  },

  setFakeApiKey(id) {
    return Api.post(`${END_POINT}/set-api-key`, { id: id });
  }

}
