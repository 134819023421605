// Display name of statuses from PrinterApi
// - returns the last one in the list not higher than requested... until we get them all specified
export const printerapiStatusInfo = (state) => (code) => {
  const statuses = state.statuses || []
  const current = statuses.reduce(function(prev, current) {
    if (current.id <= code) {
      return current;
    } else {
      return prev;
    }
  });

  return current;
}

export const printerapiStatusName = (state, getters) => (code) => {
  const status = getters.printerapiStatusInfo(code)
  return status?.name
}
