import Api from "./Api";

const END_POINT = '/product';

export default {
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  update(id) {
    return Api.post(`${END_POINT}/${id}`);
  },
}
