import Api from "./Api";

const END_POINT = '/claim';


export default{

  /* BASIC FOR TEST */
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}`);
  },
  /* END */


  claim(id, data) {
    console.log("claim got sent", id);
    return Api.post(`${END_POINT}/${id}/claim`, data);
  },
  mainClaim( ) {
    return Api.get(`/claim`);
  },
  individualClaim(id) {
    return Api.get(`/claim/${id}`);
  },
  changeIndividualClaim(id,data){
    console.log(id);
    return Api.post(`/claim/${id}/update`,data);
  },
  sendClaim(id, type) {
    return Api.get(`/claim/${id}/send?type=${type}`);
  },
   getReport(_reportData){
      return Api.post(`/claim/report`,_reportData);
  },
  getTags(){
    return Api.get(`/claim/get_tags`);
  },
  getSpeditorClaim(){
    return Api.get(`/claim/get_speditor_claim`);
  }
}