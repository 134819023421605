<!--
Just a test for automatically importing Base* named components and making them global.
-->
<template>
  <div>
    <div class="base-warning" :class="alertType">
      <slot>{{ warning }}</slot>
    </div>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseWarning',
  props: {
    warning: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'danger',
    },
  },
  data() {
    return {}
  },
  computed: {
    alertType() { return `bg-${this.type}` }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
  .base-warning {
    padding: 1em;
    display: block;
    color: white;
    opacity: 0.83;
    transition: opacity 0.6s;
    margin-bottom: 1em;
    background: #f44336;
    color: #ffffff;
  }
  @media screen and (min-width: 992px) {
    .base-warning {
      text-align: left;
    }
  }

</style>