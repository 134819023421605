export const SET_TICKETS = (state, tickets) => {
  state.tickets = tickets;
}

export const SET_TICKET = (state, ticket) => {
  const match_idx = state.tickets.findIndex(x => x.id == ticket.id)

  if (match_idx === -1) {
    state.tickets.push(ticket);
  }

  if (match_idx !== -1) {
    state.tickets[match_idx] = ticket;
  }
}
