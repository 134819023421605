//
// Wrapper Logic
//

// Get current data loading state
export const userBase_IsLoading = state => {
  return state.base_is_loading;
}
// Get current data saving state
export const userBase_isSaving = state => {
  return state.base_is_saving;
}

// Get current user data
export const currentUser = state => {
  return state.user;
}

// Get ID of current user
export const userId = state => {
  return state.user?.id;
}

// Get Account of current user
export const userAccount = state => {
  return state.user?.account;
}

// Get Accounts of current user
export const userAccounts = state => {
  const accounts = state.accounts ?? [];
  return accounts.sort(function(a, b) {
    return a.name - b.name  ||  a.name.localeCompare(b.name);
  });
}

// Get Accounts of current user
export const userAccountName = state => (id) => {
  return state.accounts.find( x => x.account == id )?.name ?? 'Unnamed';
}

// Does current user have specific role?
export const userHasRole = (state) => (role) => {
  if ( state.user.roles === undefined) {
    return false;
  }

  return state.user.roles.indexOf(role) !== -1;
}

// Does current user have any of the asked for roles?
export const userHasAnyOfRoles = (state) => (roles) => {
  if ( state.user.roles === undefined || !Array.isArray(roles)) {
    return false;
  }

  for (const role of roles) {
    if (state.user.roles.indexOf(role) !== -1) {
      return true;
    }
  }

  return false;
}

// Does current user have any of the asked for roles?
export const userHasAllOfRoles = (state, getters) => (roles) => {
  // validate parameters
  if ( !Array.isArray(roles)) {
    return false;
  }
  // check access (length of filtered roles is still the same length as unfiltered)
  return roles.filter( role => getters.userHasRole(role) ).length == roles.length;
}



//
// USERS (Seen users, including current self)
//

// Return users in userlist with certain role
export const users = (state) => {
  return state.users ?? [];
}

// Return users in userlist with certain role
export const usersWithRole = (state) => (role) => {
  return state.users.filter( user => (user.roles !== undefined) && (user.roles.includes(role)) );
}

// Return users in userlist with certain role
export const userFromList = (state) => (id) => {
  return state.users.find( user => user.id == id );
}

// Return users in userlist with certain role
export const serviceUsers_ForLists = (state) => {
  return state.users.filter( user => user.roles && user.roles.indexOf('user') !== -1 )
  .map( user => { return { value: user.id, text: `${user.firstname} ${user.lastname}` } });
}

// Return users in userlist with certain role
//export const usersCompanies = (state) => {
//  return state.users.map( user => { return {value: user.company_id, text: user.company_name} });
//}

