export default {
  namespaced: true,
  tickets: [],
  active_channel: false, // ID of looked at job?
  types: [
    { value: 'a', text: 'Production related' },
    { value: 'b', text: 'Shipping related' },
    { value: 'c', text: 'Customer related' },
    { value: 'd', text: 'Nono, not for you related', disabled: true }
  ],
}