<template>
  <b-card>
    <b-skeleton 
      v-for="(item, index) in lines"
      :key="`loader-line-${index}`"
      animation="fade"
      :width="randomPercentage()"
    ></b-skeleton>
  </b-card>
</template>

<script>
export default {
  name: 'BaseLoading',
  props: {
    lines: {
      required: false,
      default: 3,
    },
  },
  data() {
    return {}
  },
  methods: {
    randomPercentage() {
      return (Math.floor(Math.random() * 20) + 80) + "%";
    }
  }
}
</script>

<style lang="scss" scoped>
</style>