<template>
  <b-form-file
    v-bind="$attrs"
    ref="fileinput"
    accept=".png,.jpeg,.jpg"
    :placeholder="placeholderText"
    drop-placeholder="Drop file here..."
    @input="fileInput"
    class="mb-2"
  ></b-form-file>
</template>

<script>
export default {

  name: 'BaseFile64Select',
  props: {
    value: { // v-model input (modified on the outside automatically by emits)
      type: [Array, null],
      required: false,
      default: function () {
        return [];
      },
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 1200,
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 1200,
    },
    quality: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  data() {
    return {}
  },

  computed: {
    placeholderText() { 
      return this.$attrs.placeholder ?? 'Choose a file or drop it here...';
    },
  },

  mounted() {
    // WRONG WAY TO DO THIS - KEPT IT FOR ANOTHER VERSION 
    //document.addEventListener('paste', this.pasteClipboardFiles)
  },
  beforeDestroy() {
    //document.removeEventListener('paste', this.pasteClipboardFiles);
  },

  methods: {
    // Paste an image (file/direct copy)
    /*
    pasteClipboardFiles(pasteEvent, callback){

      if ( this.$refs.fileinput == pasteEvent?.target ) console.log("Same");

      console.log(pasteEvent.target);
      console.log(this.$refs.fileinput.$el);

      if(pasteEvent.clipboardData == false){
        if(typeof(callback) == "function"){
          console.log('Undefined ')
          callback(undefined);
        }
      }

      const items = pasteEvent.clipboardData.items;
      if(items == undefined){
        if(typeof(callback) == "function"){
          callback(undefined);
          console.log('Undefined 2')
        }
      }
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        const blob = items[i].getAsFile();
        let img = new Image();
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        img.src = imageUrl;
        img.onload = () => {
          // ! This could theoretically expand an image if we don't check
          this.getResizedBase64FromImage(img, {max_width: this.maxWidth, max_height: this.maxHeight, quality: this.quality})
          .then((base64small) => {
            const tmp = this.value ?? [];
            tmp.push({id: performance.now(), source: base64small, name: blob.name});
            this.$emit('input', tmp);
          })
          .catch((err) => {
            this.$toastIt(err, {variant: 'danger', autoHideDelay: 2000});
          });
        };

        //this.addImage(blob)
      }
    },
    */
    
    fileInput(files) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const file = files[i];
        // Add 'load' event listener for file reader
        reader.addEventListener("load", () => {
          let img = new Image();
          img.src = reader.result;
          img.onload = () => {
            // ! This could theoretically expand an image if we don't check
            this.getResizedBase64FromImage(img, {max_width: this.maxWidth, max_height: this.maxHeight, quality: this.quality})
            .then((base64small) => {
              const tmp = this.value ?? [];
              tmp.push({id: performance.now(), source: base64small, name: file.name});
              this.$emit('input', tmp);
            })
            .catch((err) => {
              this.$toastIt(err, {variant: 'danger', autoHideDelay: 2000});
            });
          };
        }, false);
        // Trigger load event
        if (file) {
          reader.readAsDataURL(file);
        }
      }      
      // Remove input files - we are done with these after the convert/push to store.
      this.$refs.fileinput.reset();
    },

    /**
     * Takes an image (new Image loaded with source) and convert this into a base64 encoded hopefully smaller version.
     * Returns a promise!
     * - base64 encoded source on success.
     * - error on failure.
     */
    getResizedBase64FromImage( image, given_options = {} ) {
      return new Promise((resolve, reject) => {
        // Options
        const default_options = {
          max_width: 200,
          max_height: 200,
          quality: 92,
        };
        const options = { ...default_options, ...given_options };
        // Basic return
        let data = false;
        try {
          // Make it so quality is specified in 1 to 100, instead of 0.01 to 1.
          options.quality /= 100;
          // Init canvas
          const canvas = document.createElement('canvas');
          const context = canvas.getContext("2d");
          // Find proper scale to fit image
          const xfit = options.max_width / image.width;
          const yfit = options.max_height / image.height;
          const use_scale = Math.min(xfit, yfit);
          canvas.height = image.height * use_scale;
          canvas.width = image.width * use_scale;
          // Draw image in new size
          context.drawImage(image ,0, 0, canvas.width, canvas.height);
          // Fetch new data from canvas
          data = canvas.toDataURL('image/jpeg', options.quality);
        } catch(err) {
          // Warn for now, later maybe something different?
          console.warn('Could not create a smaller version of image!', err);
          reject(err);
        }
        // return base64 endoded data -or- false when catched
        resolve(data);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>