import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import ('../views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },

  //
  // Account
  //
  {
    path: '/user/:id',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    children: [
      // UserProfile will be rendered inside User's <router-view>
      // when /user/:id/settings is matched
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "userprofile" */ '../views/UserProfile.vue'),
      },
      {
        path: 'settings',
        name: 'UserSettings',
        component: () => import(/* webpackChunkName: "usersettings" */ '../views/UserSettings.vue'),
      },
      {
        path: 'admin/usermessages',
        name: 'UserAdminMessages',
        component: () => import(/* webpackChunkName: "useradminmessages" */ '../views/UserAdminMessages.vue'),
      },
    ]
  },
  {
    path: '/product',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
/*
  {
    path: '/product/:product_sku',
    name: 'Product',
    component: () => import(/* webpackChunkName: "about" / '../views/Product.vue')
  },
*/

  {
    path: '/integration',
    name: 'Integrations',
    component: () => import(/* webpackChunkName: "integrations" */ '../views/Integrations.vue')
  },
  {
    path: '/integration/:integration_id',
    name: 'IntegrationDetails',
    component: () => import(/* webpackChunkName: "integration" */ '../views/IntegrationDetails.vue')
  },

  /*
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "about" / '../views/Orders.vue')
  },

  {
    path: '/order/:order_id',
    name: 'OrderDetails',
    component: () => import(/* webpackChunkName: "about" / '../views/OrderDetails.vue')
  },
  { // THESE 2 ITEMS ARE THE TEMPORARY ONES TO GET OLD DASHBOARD TO LOOK NEW
    path: '/orders_now',
    name: 'OrdersOld',
    component: () => import(/* webpackChunkName: "about" / '../views/OrdersOld.vue')
  },

  {
    path: '/order_now/:order_id',
    name: 'OrderDetailsOld',
    component: () => import(/* webpackChunkName: "about" / '../views/OrderDetailsOld.vue')
  },

  {
    path: '/order_now/:order_id/claims',
    name: 'ClaimDetail',
    component: () => import(/* webpackChunkName: "about" / '../views/cctest.vue')
  },
*/

  { // New table view
    path: '/claim',
    name: 'Claims',
    component: () => import(/* webpackChunkName: "claims" */ '../views/Claims.vue')
  },
  { // New table view
    path: '/job/:order_id/claim',
    name: 'NewClaim',
    component: () => import(/* webpackChunkName: "claims" */ '../views/NewClaim.vue')
  },

  {
    path: '/job/:order_id/claims',
    name: 'ClaimDetail',
    component: () => import(/* webpackChunkName: "claimdetails" */ '../views/cctest.vue')
  },
  {
    path: '/claimsXXXX',
    name: 'MainClaimPageXXXX',
    component: () => import(/* webpackChunkName: "claim1" */ '../views/MainClaimPage.vue')
  },
  {
    path: '/claim/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "404" */ '../views/Report.vue')
  },
  {
    path: '/claim/speditor_claim',
    name: 'SpeditorClaim',
    component: () => import(/* webpackChunkName: "404" */ '../views/SpeditorClaim.vue')
  },
  {
    path: '/claim/report/refund',
    name: 'Refund',
    component: () => import(/* webpackChunkName: "404" */ '../views/RefundReport.vue')
  },
  {
    path: '/claim/:claim_id',
    name: 'individualClaim',
    component: () => import(/* webpackChunkName: "claim2" */ '../views/IndividualClaim.vue'),
    props: true
  },
  //
  // TICKETS
  //
  /*
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "about" / '../views/Tickets.vue')
  },
  {
    path: '/ticket/:ticket_id',
    name: 'TicketDetails',
    component: () => import(/* webpackChunkName: "about" / '../views/TicketDetails.vue')
  },
  */

  //
  // JOBS
  //
  {
    path: '/job',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue')
  },
  {
    path: '/job/:job_id',
    name: 'JobDetails',
    component: () => import(/* webpackChunkName: "job" */ '../views/JobDetails.vue')
  },


  //
  // JOBS
  //
  {
    path: '/return',
    name: 'Returns',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/JobReturns.vue')
  },


  //
  // FILES
  //
  {
    path: '/templating',
    name: 'Templating',
    component: () => import(/* webpackChunkName: "templating" */ '../views/FilesTemplates.vue')
  },


  //
  // LOGS
  //
  {
    path: '/logs',
    name: 'IntegrationLogs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/IntegrationLogs.vue')
  },

  //
  // SYSTEM
  //
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue')
  },
  {
    path: '/testing',
    name: 'Testing',
    component: () => import(/* webpackChunkName: "testing" */ '../views/ShippingMatrix.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Home.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/wholesale',
    name: 'wholesale',
    component: () => import(/* webpackChunkName: "404" */ '../views/Wholesale.vue')
  },
  {
    path: '/claim/:claim_id/email',
    name: 'Email',
    component: () => import(/* webpackChunkName: "404" */ '../views/Email.vue')
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active-route"
})

// Triggers before any route is handled
router.beforeEach((to, from, next) => {
  // Close the sidebar whenever we land on a new route and screen is narrow
  if ( window?.innerWidth < 768 ) {
    store.state.site_settings.sidebar_visible = false
  }
  next()
})

// GOOD: Auth Guard & Initial Data Getter
/*
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.token) 
    next({ name: 'Login' })
  else {
    next()
  }
})
*/
export default router
