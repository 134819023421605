import Api from "./Api";

const END_POINT = '/order';

export default {
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}/full`);
  },

  cancel(id) {
    return Api.get(`${END_POINT}/${id}/cancel`);
  },

  restart(id) {
    return Api.get(`${END_POINT}/${id}/restart`);
  },

  updateAddress(id, data) {
    return Api.post(`${END_POINT}/${id}/update/address`, data);
  },

  express(id, data) {
    return Api.post(`${END_POINT}/${id}/update/express`, data);
  },

  claim(id, data) {
    console.log("claim got sent", id);
    return Api.post(`${END_POINT}/${id}/claim`, data);

  },
  mainClaim( ) {
    return Api.get(`/claim`);
  },
  individualClaim(id) {
    return Api.get(`/claim/${id}`);
  },
  changeIndividualClaim(id,data){
    console.log(id);
    return Api.post(`/claim/${id}/update`,data);
  },
  sendClaim(id) {
    return Api.get(`/claim/${id}/send`);
  },
}
