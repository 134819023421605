import Api from "./Api";

const END_POINT = '/integration';


export default{

  // Fetch all - with query
  all(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  // Fetch One
  fetch(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

}