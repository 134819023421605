import Vue from 'vue'

//
// SITE
//

export const SET_SITE_SETTINGS = (state, payload) => {
  state.site_settings = Object.assign({}, state.site_settings, payload)
  /*
  for (var p in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, p)) {
      Vue.set(state.site_settings, p, payload[p]);
    }
    else {

    }
  }
  */
}

export const UNSET_SITE_SETTING = (state, id) => {
  Vue.delete(state.site_settings, id);
}

//
// SAVE PAGE SETTINGS
//
export const SET_PAGE_SETTINGS = (state, {id, settings}) => {
  const page_index = state.page_settings.findIndex(x => x.id === id);
  if (page_index !== -1) {
    Vue.set(state.page_settings, page_index, {id: id, settings: settings});
  }
  else {
    state.page_settings.push({id: id, settings: settings});
  }
}

// Uploader link - Temporary until we get manual integrations to do it
export const SET_UPLOADER_LINK = (state, payload) => {
  state.uploader_link = payload;
}  


// Pending Requests
export const SET_PENDING_REQUESTS = (state, payload) => {
  state.pending_requests = payload;
}  

// Products
export const SET_PRODUCTS = (state, payload) => {
  state.products = payload;
}

export const SET_INTEGRATIONS = (state, payload) => {
  state.integrations = payload;
}

export const SET_ORDERS = (state, payload) => {
  state.order.orders = payload.items || [];
  state.order.orders_total = payload.total || 0;
}

export const SET_ORDER = (state, order) => {
  let idx = state.order.orders.findIndex( p => p.id === order.id);
  if ( idx < 0 ) {
    state.order.orders.push( order );
  } else {
    Vue.set(state.order.orders, idx, order);
  }
}

export const SET_PRODUCT = (state, product) => {
  let idx = state.products.findIndex( p => p.sku === product.sku);
  if ( idx < 0 ) {
    state.products.push( product );
  } else {
    Vue.set(state.products, idx, product);
  }
}


// WEBSOCKETS
export const SOCKET_ONOPEN = (state, event) => {
  Vue.prototype.$socket = event.currentTarget
  state.socket.isConnected = true
}
export const SOCKET_ONCLOSE = (state) => {
  state.socket.isConnected = false
}
export const SOCKET_ONERROR = (state, event) => {
  console.error(state, event)
}
// default handler called for all methods
export const SOCKET_ONMESSAGE = (state, message ) => {
  let data = JSON.parse( message.data );
  let idx;
  
  switch (data.type) {
    case 'order':
      //console.log ('got new order', data.order.id );
      
      // WHAT VERSION TO CHECK ???
      // idx = state.orders.findIndex( p => p.id === data.order.id );
      idx = state.order.orders.findIndex( p => p.id === data.order.id );

      // Resolve integration name  (COPY FROM INIT)
      //data.order.integration_name = state.integrations.find( x => x.id === data.order.integration_id).name;

      /*** OBS!  NOT DONE - PLACED FOR THOUGHT ***/
      if ( data.order.known ) {
        data.order.list_date = data.order.printerapi_data_at ? new Date(data.order.printerapi_data_at * 1000).toLocaleDateString("sv-SE") : '';
      } else {
        data.order.list_date = new Date(data.order.created_at * 1000).toLocaleDateString("sv-SE");
      }
      // END: Resolve integration name  (COPY FROM INIT)


      if ( idx < 0 ) {
        state.order.orders.push( data.order ); // V2 - same direct approach
      } else {
        Vue.set(state.order.orders, idx, data.order);
      }
      break;
    
    default:
      //console.log('Got message: ',message.data);
      state.socket.message = message.data;
      break;
    }
}

// mutations for reconnect methods
export const SOCKET_RECONNECT = (state, count) => {
  if( count === 'nisse' ) console.info(state, count) // just wanted it to stop
//      Vue.prototype.$socket.sendObj( { token: '1234'})
}

export const SOCKET_RECONNECT_ERROR =(state) => {
  state.socket.reconnectError = true;
}


////////claim mutations //////////////////

export const pushDamagedOrder=(state,order)=>{
  state.damagedOrders.push(order);
}
export const itemIndex=(state,value)=>{
  state.itemPosition[value.index] = value.amount;
}
export const assembleDamagedOrders= function(state){
  //console.log("orders got assembled");
  //console.log("the original order",state.originalOrder);
  for(var i =0;i<state.itemPosition.length;i++){
    if(state.itemPosition[i]!=0&&state.itemPosition[i]!=undefined){
      var newItem = {};
      newItem.quantity = state.itemPosition[i];
      console.log(state.originalOrder);
      newItem.line_id = state.originalOrder.fulldata.items[i].line_id;
      state.damagedOrders.push(newItem);
     //state.damagedOrders.push(state.originalOrder.items[i]);
     //state.damagedOrders[state.damagedOrders.length-1].quantity = state.itemPosition[i];
    }
    
  }
  state.newOrder.images = state.images;
  state.newOrder.id = state.originalOrder.id;
  state.newOrder.account = state.originalOrder.account;
  state.newOrder.items = state.damagedOrders;
  state.newOrder.claimCategory = state.claimCategory;
  state.newOrder.refund = state.refund;
  state.newOrder.claimText = state.commentText;
  //console.log("new orders ", state.newOrder);
}
export const storeImage=(state,value)=>{
 
   state.images.push(value);
   //console.log("final images",state.images);
}
export const storeOriginalImages=(state,value)=>{
  state.originalImages.push(value);
  //console.log("original",state.originalImages);
}
export const deleteAfterRender=(state,value)=>{
  state.originalImages.splice(value,1);

  //console.log("images has been removed");
}
export const deleteImages =(state,value)=>{
  state.images.splice(value,1);
}

export const importOrderData =(state,value)=>{
  state.originalOrder = value;
  //console.log("imported data", value);
}

export const importClaimCategory =(state,value)=>{
  state.claimCategory = value;
  //console.log("imported Category", value);
}

export const importRefund = (state,value)=>{
  //console.log("refund!!");
  state.refund = value;
}

export const deleteClaimData = (state)=>{
  //console.log("the claim data got deleted");
  //state.originalOrder=null;
  state.damagedOrders=[];
  state.itemPosition=[];
  state.newOrder={};
  state.images=[];
  state.originalImages=[];
  state.refund = false;
  state.claimCategory={transport_damage:false,lost_transport:false,defect_packaging:false,wrong_packaging:false};
  state.commentText = ''
}

export const tester = ()=>{
  //console.log("i say something that will give me a clue",state);
}
///claim mutations////////////////////////////////////////////

export const commentText =(state,value)=>{
state.commentText = value;
}