import Vue from 'vue'
import axios from 'axios';

/**
 * Initial Site Data
 */
export const fetchInitialData = ({commit}) => {
  let init_url = `${process.env.VUE_APP_BASE_API}/init`;
  axios.get(init_url)
  .then((result) => {
    //console.log(result.data)
    //context.commit( 'SET_INTEGRATIONS', result.data.integrations);
    //context.commit( 'SET_PRODUCTS', result.data.products);
    
    // Current User (id, name etc)
    commit( 'user/SET_CURRENT_USER', result.data.user);
    // All Users (list of seen Users)
    commit( 'user/SET_USERS', result.data.users);

    // Take starting "The" and move it to the end on account names (Just testing, I don't know if we want this)
    let accounts = result.data.accounts;
    accounts = accounts.map( function(account) {
      if ( account.name.indexOf("The ") === 0 ) {
        account.name = account.name.substr(4) + ', The'
      }
      return account;
    });
    // List of seen Accounts
    commit( 'user/SET_ACCOUNTS', accounts);
    // Temporary Uploader link - Only valid for customers, not admins.
    commit( 'SET_UPLOADER_LINK', result.data.uploader_link);
  });
}

//
// Site settings
//
export const saveSiteSettings = ({commit}, payload) => {
  commit( 'SET_SITE_SETTINGS', payload);
}

//
// Page settings
//
export const savePageSettings = ({commit}, {id, settings}) => {
  commit( 'SET_PAGE_SETTINGS', {id, settings});
}


/**
 * Pending Requests
 * Manually set/removed names/tags used to show/hide areas when requests are beeing processed
 * 
 * @param {*} name 
 */

// Inc pending request name/names
export const addPendingRequest = ({commit, getters, state}, name) => {
  if (!getters.hasPendingRequests(name)) {
    commit( 'SET_PENDING_REQUESTS', [...state.pending_requests, name]);
  }
}

// Remove pending request name/names
export const removePendingRequest = ({commit, state}, name) => {
  const pending = state.pending_requests.filter(function(ele){ 
    return ele != name; 
  });
  commit( 'SET_PENDING_REQUESTS', pending);
}



export const setProduct = (context, product) => {
  context.commit( 'SET_PRODUCT', product );
}

export const sendMessage = (context, message) => {
  Vue.prototype.$socket.sendObj(message)
}