export const SET_BASE_LOADING_STATE = (state, isLoading) => {
  state.base_is_loading = isLoading;
}

export const SET_BASE_SAVING_STATE = (state, isSaving) => {
  state.base_is_saving = isSaving;
}

export const SET_CURRENT_USER = (state, user) => {
  state.user = user;
}

export const SET_USERS = (state, users) => {
  state.users = users;
}

export const SET_ACCOUNTS = (state, accounts) => {
  state.accounts = accounts;
}
