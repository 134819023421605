// get token
export const token = state => {
  return state.token
}

// get site settings
export const getSiteSetting = (state) => (key) => {
  if ( ! Object.prototype.hasOwnProperty.call(state.site_settings, key) ) return
  return state.site_settings[key] || null
}


// get page data
export const getPageSettings = (state) => (page_id) => {
  const page_data = state.page_settings.find(x => x.id === page_id);
  return page_data?.settings || {}
}


// get uploader link
export const getUploaderLink = state => {
  //console.log(state.uploader_link);
  return state.uploader_link
}

//
// Current Active Requests
//
// - If any updating routines are manually set
export const hasPendingRequests = (state) => (name_or_names) => {
  let names = [];

  if (name_or_names) {
    if (Array.isArray(name_or_names)) {
      names = name_or_names;
    }
    else {
      names = [name_or_names];
    }
    return state.pending_requests.filter(value => names.includes(value)).length > 0 || false;
  }

  return state.pending_requests.length > 0;
}


//
// Companies / Accounts
// 


//
// integrations
//

// Get integration name by it's id.
export const integration_name_by_id = (state) => (id) => {
  return state.integrations.find( x => x.id == id).name ?? '';
}


//
// product_types/Types
//

// - Get all product_types (full)
export const product_types = state => {
  return state.product_types;
}

// - Get one template by it's id
export const templateById = (state) => (id) => {
  return state.product_types.find(x => x.id == id) ?? false;
}

// - Get one template's fields by it's id
export const templateFieldsById = (state) => (id) => {
  const template = state.product_types.find(x => x.id == id) ?? false;
  if (template) {
    return template['fields'];
  }
  return [];
}

//
// Products
//

// - Get all products
export const getProducts = state => {
  return state.products ?? [];
}

// - Get one product by it's sku
export const getProductBySku = (state) => (sku) => {
  //console.log("Products", state.products);
  return state.products.find(x => x.sku == sku) ?? false;
}

// - Get one order by it's id
export const getOrderById = (state) => (id) => {
  return state.orders.find(x => x.id == id) ?? false;
}

//
// Integrations
//

export const getIntegrations = state => {
  return state.integrations;
}

export const integrationById = (state) => (id) => {
  return state.integrations.find(x => x.id == id) ?? false;
}

export const integrationNameById = (state, getters) => (id) => {
  const i = getters.integrationById(id);
  return i.name || ''; // Fallback to integration name?
}


//
// Countries
//
export const availableDeliveryCountries = (state) => {
  return state.countries ?? [];
}

export const countryByCode = (state) => (code) => {
  return state.countries.find((x) => x.id === code)?.name || "";
}

//
// Helpers
//
export const timestampAsDate = () => (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString("sv-SE");
}

// Check if an item is included in an array
export const inArray = () => (needle, haystack) => {
  if (!Array.isArray(haystack))
    return false;

  const length = haystack.length;
  for(var i = 0; i < length; i++) {
      if(haystack[i] == needle)
        return true;
  }
  return false;
} 