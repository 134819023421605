export default {
  // Just placeholder stuff.-.- nothing here is real..
  statuses: [
    { id:  -1, class: 'danger', name: '-' },
    { id:   0, class: 'success', name: 'Incoming' },
    { id:   2, class: 'success', name: 'Downloading' },
    { id:   6, class: 'success', name: 'Verifying' },
    { id:   9, class: 'warning', name: 'On-hold' },
    { id:  10, class: 'success', name: 'Queued' },
    { id:  11, class: 'success', name: 'In production' },
    { id:  19, class: 'success', name: 'Packed' },
    { id:  80, class: 'warning', name: 'Warnings' },
    { id:  97, class: 'danger', name: 'Failed' },
    { id:  99, class: 'secondary', name: 'Cancelled' },
    { id: 100, class: 'success', name: 'Complete' },
  ]
}