import Order from "../../../apis/Order";

/**
 * Get all Orders based on query filter.
 * 
 * @param {*} filter : Object of key/values
 */
export const fetchOrdersToStorePromise = ({ commit }, filter) => {
  var params = Object.keys(filter).map(function(key) {
    return `${key}=${encodeURIComponent(filter[key])}`;
  }).join('&');

  return Order.all(params).then(response => {
    commit('SET_ORDERS', {items: response.data.items, total: response.data.total});
  }).catch((err) => {
    console.log(err);
    commit( 'SET_ORDERS', {items: [], total: 0} );
  });
}


/**
 * Fetch an Order
 * 
 * @param {*} id : Order ID
 * @returns Order
 */
export const getOrderPromise = (store, id) => {
  return Order.fetch(id);
}


/**
 * Cancel an Order
 * 
 * @param {*} id : Order ID
 * @returns Order
 */
export const cancelOrderPromise = (store, id) => {
  return Order.cancel(id);
}


/**
 * Restart an Order
 * 
 * @param {*} id : Order ID
 * @returns Order
 */
 export const restartOrderPromise = (store, id) => {
  return Order.restart(id);
}


/**
 * Address update
 * 
 * @param {*} order_id : Order ID
 * @returns Order promise
 */
export const updateAddressPromise = (store, {id, data}) => {
  return Order.updateAddress(id, data);
}


/**
 * Express update
 * 
 * @param {*} id Printerapi job id
 * @param { production: Number, delivery: Number } data At least one needed
 * @returns Order promise
 */
 export const updateExpressPromise = (store, {id, data}) => {
  return Order.express(id, data);
}
