// Get all the orders
export const getOrders = state => {
  return state.orders;
}


// Display name of statuses
export const getOrder = state => {
  return state.order;
}


// Display name of statuses - NOT IN USE
export const orderStatusName = () => (code) => {
  const statuses = [
    { id: 'pending', name: 'Pending order' },
    { id: 'api_queue', name: 'Queued ordering' },
    { id: 'api', name: 'Ordered' },
    { id: 'cancelled', name: 'Cancelled' },
  ];
  
  const status = statuses.find(x => x.id == code) ?? { id: null, name: "Unknown" };
  return status.name;
}



