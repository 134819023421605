export default {
  namespaced: true,

  // Page / Data / Settings
  base_is_loading: true,
  base_is_saving: false,

  user: [],
  users: [],
  accounts: [],
}