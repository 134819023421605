import User from "../../../apis/User";

/**
 * Set loading state
 * 
 * @param {*} setLoading : Userdata
 */
export const setUserLoadingState = ({commit}, isLoading) => {
  commit('SET_BASE_LOADING_STATE', isLoading);
}

/**
 * Set saving state
 * 
 * @param {*} setSaving : Userdata
 */
 export const setUserSavingState = ({commit}, isSaving) => {
  commit('SET_BASE_SAVING_STATE', isSaving);
}


/**
 * Set user - NOT USED
 * 
 * @param {*} user : Userdata
 */
export const setUser = ({commit}, user) => {
  commit('SET_CURRENT_USER', user);
}

/**
 * Fetch an User - NOT USED
 * 
 * @param {*} id : User ID
 * @returns User Promise
 */
export const getUserPromise = (_store, id) => {
  return User.fetch(id);
}


/**
 * Fetch User Files
 * 
 * @param {*} order_id : Order ID
 * @returns Order promise
 */
export const fetchPrintFiles = (_store, id) => {
  return User.fetchPrintFiles(id);
}

// Set a user key (for admins)
export const setFakeApiKey = (_store, id) => {
  return User.setFakeApiKey(id);
}