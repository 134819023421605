<!--
Just a test for automatically importing Base* named components and making them global.
-->
<template>
  <div class="base-notice" :class="noticeType">
    <slot>{{ content }}</slot>
  </div>
</template>

<script>
export default {
  name: 'BaseNotice',
  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: 'secondary',
    }
  },
  
  data() {
    return {}
  },

  computed: {
    noticeType() {
      switch (this.variant) {
        // Bootstrap 4
        case 'danger': return `bg-${this.variant} text-white`;
        case 'info': return `bg-${this.variant} text-white`;
        case 'warning': return `bg-${this.variant} text-dark`;
        case 'primary': return `bg-${this.variant} text-white`;
        case 'secondary': return `bg-${this.variant} text-white`;
        // Custom
        case 'purple': return `bg-${this.variant} text-white`;
      }
      return '';
    }
  },
  
  methods: {},
}
</script>

<style lang="scss" scoped>
  .base-notice {
    padding: 1em;
    display: block;
    transition: opacity 0.6s;
    margin-bottom: 1em;
  }
  @media screen and (min-width: 992px) {
    .base-notice {
      text-align: left;
    }
  }

  .bg-purple { background-color: rgba(99, 20, 99, 0.667); }
</style>