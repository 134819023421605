import Api from "./Api";

const END_POINT = '/ticket';

export default {
  all() {
    return Api.get(`${END_POINT}/my-store`);
  },

  query(query) {
    return Api.get(`${END_POINT}?${query}`);
  },

  fetch(id) {
    return Api.get(`${END_POINT}/${id}`);
  },

  create(ticketSettings) {
    return Api.post(`${END_POINT}`, ticketSettings);
  },

  update(id, data) {
    return Api.post(`${END_POINT}/${id}`, data);
  },

  close(id) {
    return Api.post(`${END_POINT}/${id}/close`);
  },

  // MESSAGES

  fetchMessages(id) {
    return Api.get(`${END_POINT}/${id}/messages`);
  },

  createMessage(id, data) {
    return Api.post(`${END_POINT}/${id}/message`, data);
  },

}
