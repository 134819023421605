import store from '../store/index'
import axios from "axios";

const Api = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}`
});

Api.interceptors.request.use(function (config) {
  config.headers.Authorization = store.state.token;
  return config;
});

export default Api;
