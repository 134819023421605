import Integration from "../../../apis/Integration";

/**
 * Get all Jobs
 * 
 * @param {*} filter : Object of key/values
 * @returns Promise of Integrations
 */
 export const queryIntegrationsPromise = (store, filter) => {
  let queryParts = [];
  for (var p in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, p)) {
      queryParts.push(encodeURIComponent(p) + "=" + encodeURIComponent(filter[p]));
    }
  }
  const params = queryParts.join('&');

  return Integration.all(params);
}


/**
 * Fetch an Integration
 * 
 * @param {*} id : ID
 * @returns Promise of Integration
 */
export const getIntegrationPromise = (store, id) => {
  return Integration.fetch(id);
}
